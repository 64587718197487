import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

import { Link } from 'react-router-dom';
import { Content } from '../../styles/common';

export const Header = styled.div`
  height: 64px;
  background: transparent;

  &,
  & * {
    color: ${({ theme }) => theme.text};
  }

  @media (max-width: 440px) {
    height: 108px;
    border-bottom: solid 1px ${({ theme }) => theme.text};
    margin-bottom: 36px;
  }

  @media (max-width: 200px) {
    height: 64px;
    border: none;
  }
`;

export const ContentHeader = styled(Content)`
  padding: 16px;
  justify-content: space-between;
  display: flex;

  @media (max-width: 440px) {
    display: block;
    padding: 0px;
  }
`;

export const Options = styled.div`
  margin-top: 6px;
  display: flex;

  @media (max-width: 440px) {
    margin-top: 4px;
    padding: 24px 16px 8px 16px;
    background: rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }

  @media (max-width: 240px) {
    .icon-button-language {
      display: none;
    }
  }

  @media (max-width: 200px) {
    display: none;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 440px) {
    padding: 0 24px;
    position: relative;
    top: 16px;
  }
`;

export const LogoName = styled.span`
  color: ${({ theme }) => theme.text};
`;

export const FeaturedText = styled.span`
  color: ${({ theme }) => theme.currentColor};
`;

export const Button = styled(Link)`
  background: transparent;
  border: none;
  outline: none;
  text-decoration: none;

  @media (min-width: 640px) {
    padding-left: 174px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const IconButton = styled.div`
  background-image: url(../images/${(props) => props.icon}-${({ theme }) =>
    theme.value}.svg);
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-flex;
`;

export const TextButton = styled.span`
  font-size: 14px;
`;

export const InputLanguage = styled(FormControl)`
  margin-right: 16px !important;
  min-width: 136px;
  width: 136px;

  & .MuiInput-underline:before,
  & .MuiInput-underline:after,
  & .MuiInput-underline.Mui-focused:after {
    display: none !important;
  }

  & .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 0;
  }

  & .MuiSelect-select:focus {
    background: transparent;
  }

  & .MuiSelect-selectMenu {
    color: #fff;
    text-transform: uppercase;
  }

  & .MuiSelect-selectMenu span {
    margin-left: 8px;
  }

  & * {
    outline: none !important;
    border: none;
  }

  @media (max-width: 240px) {
    width: auto;
  }
`;

export const MenuNavBar = styled.div`
  display: flex;
  width: 100%;
  border-left: solid 1px;
  height: 32px;
  margin: 0 24px;

  @media (max-width: 440px) {
    width: auto;
    margin-top: -14px;
    margin-left: 154px;
  }
`;

export const NavItem = styled(Button)`
  display: block;
  padding: 0 24px;
`;

export const Logo = styled.div`
  background-image: url(./images/logo.png);
  background-size: 100%;
  height: 64px;
  width: 120px;
  background-repeat: no-repeat;
`;
