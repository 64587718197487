import styled from 'styled-components';
import { Content } from '../../styles/common';

export const ContentFooter = styled(Content)`
  display: block;

  @media (max-width: 1100px) {
    padding: 0 24px;
  }

  @media (max-width: 200px) {
    display: none;
  }
`;

export const ContentInfoFooter = styled.div`
  display: inline-flex;

  @media (max-width: 640px) {
    display: block;
  }

  @media (max-width: 240px) {
    ul {
      list-style: none;
      padding: 0;
    }
  }
`;

export const Area = styled.div`
  display: inline-block;
  padding: 0 44px;

  @media (max-width: 640px) {
    padding: 0 8px;
  }
`;

export const Footer = styled.div`
  background: rgba(0, 0, 0, 0.01);

  &,
  & * {
    color: ${({ theme }) => theme.text};
  }
`;

export const Sentence = styled.p`
  font-size: 18px;
  text-align: right;
`;

export const CopyRight = styled.p`
  border-top: solid 1px ${({ theme }) => theme.text};
  padding: 24px 0;
  text-align: center;
`;

export const AreaLinks = styled.div`
  @media (max-width: 720px) {
    max-width: 180px;
  }

  @media (max-width: 640px) {
    max-width: none;
  }
`;

export const LinkSocialNetwork = styled.a`
  display: inline-flex;
  box-sizing: border-box;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const IconSocialNetwork = styled.div`
  height: 44px;
  width: 44px;
  background-image: url(../images/${(props) => props.icon}-${({ theme }) =>
    theme.value}.png);
  background-size: 100%;
  background-repeat: no-repeat;

  @media (max-width: 870px) {
    height: 54px;
    width: 54px;
  }
`;
