import React, { Suspense, useState } from 'react';
import { Router, Route, Switch } from 'react-router';

import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Profile from './screens/profile/Profile';
import Projects from './screens/projects/Projects';
import Project from './screens/project/Project';

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, colorsPallete } from './config/theme';
import { GlobalStyles } from './config/global';

import { AppBackground } from './App.styles';

import BackgroundGradient from './components/backgroundGradient';

const history = createBrowserHistory();

const App = () => {
  const { t } = useTranslation('common');
  const [theme, setTheme] = useState('light');
  const [logoColor, setLogoColor] = useState(colorsPallete[0]);

  const toggleTheme = () =>
    theme === 'light' ? setTheme('dark') : setTheme('light');

  const setCurrentColor = (theme) => ({
    ...theme,
    currentColor: logoColor || colorsPallete[0],
  });

  const setThemeObject = () => (theme === 'light' ? lightTheme : darkTheme);

  const colorsGradient = {
    dark: ['#111', '#000', '#232323', '#080000'],
    light: ['#f1f2f6', '#ffffff', '#dfe4ea80', '#ffffff'],
  };

  return (
    <ThemeProvider theme={setCurrentColor(setThemeObject(theme))}>
      <GlobalStyles />
      <BackgroundGradient colors={colorsGradient[theme]} degrees={45} />
      <AppBackground>
        <Suspense fallback="loading">
          <Router basename="/" history={history}>
            <Header changeTheme={toggleTheme}></Header>
            <Switch>
              <Route
                path="/"
                exact
                render={(props) => (
                  <Profile t={t} setLogoColor={setLogoColor} {...props} />
                )}
              />
              <Route
                path="/projects"
                exact
                render={(props) => (
                  <Projects t={t} setLogoColor={setLogoColor} {...props} />
                )}
              />
              <Route
                path="/project/:project"
                exact
                render={(props) => (
                  <Project t={t} setLogoColor={setLogoColor} {...props} />
                )}
              />
            </Switch>
            <Footer t={t}></Footer>
          </Router>
        </Suspense>
      </AppBackground>
    </ThemeProvider>
  );
};

export default App;
