export const colorsPallete = ['#e84393', '#2e80d4'];

export const lightTheme = {
  value: 'light',
  body: '#ffffff',
  text: '#121212',
  transparentLine: '#ccc',
  toggleBorder: '#FFF',
  linkColor: colorsPallete[0],
  gradient: 'linear-gradient(#6c5ce7, #74b9ff)',
  primaryColor: colorsPallete[0],
  secondColor: colorsPallete[1],
  grayColor: '#555',
  currentColor: colorsPallete[0],
};

export const darkTheme = {
  value: 'dark',
  body: '#121212',
  text: '#ffffff',
  transparentLine: '#333',
  toggleBorder: '#6B8096',
  linkColor: colorsPallete[0],
  gradient: 'linear-gradient(#111, #2c3e50)',
  primaryColor: colorsPallete[0],
  secondColor: colorsPallete[1],
  grayColor: '#ccc',
  currentColor: colorsPallete[0],
};

export const theme = {
  primary: '#6c5ce7',
  secondary: '#2e80d4',
};
